body {
  background-color: #676a7e;
}

.App {
  color: #f8f8f8;
  text-align: center;
}

.title {
  padding-top: 25px;
  padding-bottom: 5px;
}

.problem-name {
  text-align: left;
  padding-bottom: 10px;
}

.problem-name .btn {
  color: #f8f8f8;
  font-size: 1.15em;
}

.tc-link {
  color: #9bdaff;
  text-decoration: underline;
  font-size: 1.1em;
}

.tc-link:hover {
  color: #61c5ff;
}

.bottom {
  margin-top: 10px;
}

.submit-btn {
  margin-top: 10px;
  max-width: 150px;
  width: 100%;
  color: #fafafa;
  background-color: #2b2c39;
}

.copy-btn {
  padding-top: 0px;
  padding-bottom: 3px;
  padding-left: 8px;
  padding-right: 8px;
}

.submit-btn:hover {
  background-color: #181a26;
}

.show-btn {
  width: 100%;
  color: #fafafa;
  background-color: #404353;
  border-color: #404353;
}

.show-btn:hover {
  background-color: #2b2c39;
}

.submit-btn:disabled {
  background-color: #282a36;
}

.hasil .container {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 50px;
}

.hasil .card {
  white-space: pre-line;
  margin-top: 0.75rem;
  padding-top: 0.6rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
}